<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Screen_Printer/SP1-C.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                   
                  Provides the highest productivity as well as 6 SIGMA level high quality printing
                  </h2>
                  <br>
                  <ul class="container">
                    <li>
                      <h5>
                        6 sigma level of high quality printing
                      </h5>
                    </li>
                    <li>
                      <h5>
                        Corrects print offset automatically by feeding back defective SPI printing
                      </h5>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />
            <h1><strong> Specifications </strong></h1>
            <br /><br />
            <div class="row container" style="font-size:18px;">
              <div class="container-fluid">
               
                  <div class="table-responsive">
                    <table class="table table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row" style="width: 400px">
                            Align Repeatability
                          </th>
                          <td style="font-size: 12px">±12.5um @ 6σ</td>
                        </tr>
                        <tr>
                          <th scope="row">Wet Print</th>
                          <td style="font-size: 12px">±25um @ 6σ</td>
                        </tr>
                        <tr>
                          <th scope="row">Cycle Time</th>
                          <td style="font-size: 12px">
                            5sec (Excl. Printing Time)
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Board Handling (mm)</th>
                          <td style="font-size: 12px">
                           Max. L330 × W250 (Single Lane) / L330 × W250 (Dual Lane/Option)
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Stencil (mm)</th>
                          <td style="font-size: 12px">
                            L550 × W650 ~ L650 × W550
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                
                <br><br>
                <h1><strong>Features</strong></h1>
                <br><br>

                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp1_c feature/High Quality.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> 
High Quality </strong>
                      </h1>
                      <h3><strong> 6 sigma level of high quality printing</strong></h3>
                      <p>
                       Applies new alignment mechanism for high quality printing
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp1_c feature/hight productivité.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> High Productivity </strong>
                      </h1>
                      <h3><strong> Rapid Job Change</strong></h3>
                      <p>
                        Allows rapid job change by automatically moving to the printing position for print setting when loading the stencil mask.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp1_c feature/hight realabilité.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> High Reliability</strong>
                      </h1>
                      <h3><strong> Various Options Available</strong></h3>
                      <p>
                       Connects an external Air Conditioner to maintain a constant print temperature for a stable print environment..
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp1_c feature/easy operation.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Easy Operation </strong>
                      </h1>
                      <h3>
                        <strong>
                          Increased User Convenience</strong
                        >
                      </h3>
                      <p>
                        Operational convenience is maximized by monitoring a variety of information about production.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>